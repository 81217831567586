import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FileViewerComponent implements OnInit {
  @Input() fileName:string ="";
  @Input() fileUrl:string ="";
  @Input() fileType: string = "";
  constructor() { }

  ngOnInit() {
  }

}
