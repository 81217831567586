import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../interface/interface';
import { AppWebRoutes } from '../../../../core/constants/app-web-routes';
import { ApproveLogStatus, RequestView } from '../../../../shared/constants/shared-constant';
import { MakerCheckerService } from '../../../../shared/services/maker-checker/maker-checker.service';
import { DataService } from 'src/app/shared/services/data/data.service';
import { ApproveOrRejectDialogComponent } from '../approve-or-reject-dialog/approve-or-reject-dialog.component';

@Component({
  selector: 'app-approval-request-details-dialog',
  templateUrl: './approval-request-details-dialog.component.html',
  styleUrls: ['./approval-request-details-dialog.component.scss'],
})
export class ApprovalRequestDetailsDialogComponent implements OnInit {
  dataValue;
  requestType;
  elementData;
  promoCodeDetails;
  showExport: boolean = false;
  approveLogStatus = ApproveLogStatus;
  public requestView: Array<RequestView>;

  constructor(
    public dialogRef: MatDialogRef<ApprovalRequestDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public makerCheckerService: MakerCheckerService,
    public dataService: DataService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.requestView = [];
    this.requestView[ 'page' ] =  { label: '', path: '' };
    this.elementData = this.data['elementData'];
    this.requestType = this.elementData['requestType'];
    this.showExport = false;
    this.fetchCommentHistory();
  }

  fetchCommentHistory() {
    this.makerCheckerService
      .getAllDataById(
        `${AppWebRoutes.MAKER_CHECKER.approvalLog}/${this.elementData?.id}`, null
      )
      .subscribe((response: any) => {
        this.dataValue = response;
      });
  }

  doAction(action: string) {
    if(!!action) {
      let nextLevel = this.elementData.level ? this.elementData.level +1: 1;
      if(!nextLevel) {
        nextLevel = 1;
      }
      this.dialog.open(ApproveOrRejectDialogComponent, {
        width: 'auto',
        data: { 
          title: action === 'approve' ? 'Approve Request' : 'Reject Request',
          requestType: this.requestType, 
          elementData: {...this.elementData, ...{level: nextLevel}, ...{action: action} },
        },
        autoFocus: false
      });
    }
  }

  exportCsv() {
    this.makerCheckerService.exportCsv(
      AppWebRoutes.MAKER_CHECKER.exportCampaign,
      `campaignDifferentialPricing_${Date.now()}`,
      this.dataService.globalFilter.uivalue.hubId,
      { campaignId: this.elementData?.requestId },
      true
    );
  }

  showExportButton(showExport:boolean) {
    if(!!showExport) {
      this.showExport = true;
    } else {
      this.showExport = false;
    }
  }

  doCloseDialog(closeDialog:boolean) {
    if(!!closeDialog) {
      alert('Not a genuinue Request Type');
      this.dialogRef.close();
    }
  }
}
