<div class="catalog-dialog">
  <h2 mat-dialog-title>
    <!-- {{ data.title }} -->
      Send for approval
  </h2>
  <mat-dialog-content class="app-crud-popup">
    <app-crud [form]="form" [_crud]="makerCheckerCrud"> </app-crud>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="warn" [mat-dialog-close]="false">
      Cancel
    </button>
    <button mat-button color="primary" [disabled]="form?.invalid" [mat-dialog-close]="true"
    (click)="onProceed()">
      Proceed
    </button>
  </mat-dialog-actions>
</div>
