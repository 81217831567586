<div class="catalog-dialog" *ngIf="!!elementData && !!title">
  <h2 mat-dialog-title>
    {{ title }}
  </h2>
  <mat-dialog-content class="app-crud-popup" *ngIf="form && makerCheckerCrudRequest && makerCheckerCrudRequest.length">
    <app-crud [form]="form" [_crud]="makerCheckerCrudRequest"> </app-crud>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button color="warn" [mat-dialog-close]="true">
      Cancel
    </button>
    <button mat-button color="primary" [disabled]="remarkRequired && !form?.controls['remarks']?.value"
    (click)="onProceed()">
      Proceed
    </button>
  </mat-dialog-actions>
</div>
