import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { DialogData } from 'src/app/shared/interface/interface';
import { FormService } from 'src/app/shared/services/form/form.service';
import { FormGroup } from '@angular/forms';
import { MakerCheckerService } from 'src/app/shared/services/maker-checker/maker-checker.service';
import { MakerCheckerType } from 'src/app/shared/constants/shared-constant';
import { DataService } from 'src/app/shared/services/data/data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-send-for-approval-dialog',
  templateUrl: './send-for-approval-dialog.component.html',
  styleUrls: ['./send-for-approval-dialog.component.scss'],
})
export class SendForApprovalDialogComponent implements OnInit, AfterViewInit {
  requestType = null;
  elementData;
  makerCheckerCrud = [
    {
      key: 'assignedTo',
      placeholder: 'Assigned To',
      type: 'select',
      options: [],
      validations: { required: true },
      defaultValue: '',
      validationMessages: [''],
      uiKey: 'name',
      apiKey: 'userId',
      isReadOnly: false,
    },
    {
      key: 'remarks',
      placeholder: 'Remarks',
      type: 'textarea',
      defaultValue: '',
      isReadOnly: false,
    },
  ];
  @ViewChild('btnRef', { static: false }) buttonRef: MatButton;
  form: FormGroup;
  crud;

  constructor(
    public dialogRef: MatDialogRef<SendForApprovalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formService: FormService,
    private makerCheckerService: MakerCheckerService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.requestType = this.data['requestType'];
    this.elementData = this.data['elementData'];
    this.form = this.formService.createFormGroup(this.makerCheckerCrud);
    this.fetchAssignees();
  }

  fetchAssignees() {
    this.makerCheckerService
      .getAllDataById(
        `${AppWebRoutes.MAKER_CHECKER.config}?requestType=${
          this.requestType
        }&level=${this.elementData.level || 1}`,
        {}
      )
      .subscribe((response: any) => {
        this.makerCheckerCrud[0].options =
          response?.approver.map((approver) => ({
            value: approver.userId,
            placeholder: `${approver.name} (${approver.email})`,
          })) || [];
      });
  }

  fetchRequestBody() {
    const requestBody = {
      requestId: null,
      requestType: this.requestType,
      requestExpiryDate: null,
      name: '',
      assignedTo: this.form.controls['assignedTo'].value,
      remarks: this.form.controls['remarks'].value,
    };
    switch (this.requestType) {
      case MakerCheckerType.INVITE_CODE:
      case MakerCheckerType.CAMPAIGN:
        requestBody.requestId = this.elementData.id;
        requestBody.name = this.elementData.name;
        break;
      case MakerCheckerType.FLASH_DEAL:
        requestBody.requestId = this.elementData.id;
        requestBody.name = this.elementData.name;
        requestBody.requestExpiryDate = this.elementData.startTime;
        break;
      case MakerCheckerType.COUPON:
      case MakerCheckerType.REWARD:
        requestBody.requestId = this.elementData.id;
        requestBody.name = this.elementData.name;
        requestBody.requestExpiryDate = this.elementData.startDate;
        break;
      case MakerCheckerType.INVITE_CODE_VALIDITY:
        requestBody.requestId = this.elementData.id;
        requestBody.name = this.elementData.name ? this.elementData.name : `${MakerCheckerType.INVITE_CODE_VALIDITY}_${this.elementData.id}`;
        requestBody.requestExpiryDate = this.elementData.startDate;
        break;
        case MakerCheckerType.AUTO_ADD_SAMPLE:
        requestBody.requestId = this.elementData.offerId;
        requestBody.name = this.elementData.offerName;
        requestBody.requestExpiryDate = this.elementData.startDate;
        break;
      case MakerCheckerType.ORDER_SAMPLE:
        requestBody.requestId = this.elementData.orderSampleId;
        requestBody.name = this.elementData.productName;
        requestBody.requestExpiryDate = moment(this.elementData.orderDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        break;
    }
    return requestBody;
  }

  onProceed() {
    const requestBody = {
      ...this.fetchRequestBody(),
    };
    this.makerCheckerService
      .addData(AppWebRoutes.MAKER_CHECKER.makerChecker, { ...requestBody })
      .subscribe((response) => {
        this.dialogRef.close();
        if (response) {
          this.dataService.refreshList.next(true);
        }
      });
  }

  ngAfterViewInit(): void {
    if (this.buttonRef) {
      this.buttonRef.focus();
    }
  }
}
