import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { environment } from '../../../../environments/environment';
import { formServiceActions } from '../../constants/shared-constant';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input()
  _search;
  @Input()
  form: FormGroup;
  @Output()
  searchFilters: EventEmitter<any> = new EventEmitter<any>();
  lastSearchFilters = {
    count: 0,
    metaData: {},
    filters: {},
    isFilterPreFilled: false
  };
  step: boolean;

  constructor(public dataService: DataService) {}

  ngOnInit() {
    const storageFilters = this.dataService.getLocalStorage(
      environment.localStorageKeys.FILTERS
    );
    if (storageFilters && Object.keys(storageFilters).length > 0) {
      this.lastSearchFilters = storageFilters;
    }
    this.dataService.searchFilters = this.dataService.deepClone(
      this.lastSearchFilters
    );
    if (this.lastSearchFilters && this.lastSearchFilters.count) {
      this._search.forEach(search => {
        if (this.lastSearchFilters.filters.hasOwnProperty(search.key)) {
          if (
            search.targetIndex &&
            search.targetIndex !== -1 &&
            this.lastSearchFilters.filters[search.key] !== ''
          ) {
            this._search[
              search.targetIndex
            ].optionsParams = this.dataService.getLocalStorage(
              environment.localStorageKeys.OPTIONPARAMS
            );
          }
          if (search.chipOptions && search.type !== 'select') {
            search.chipOptions['key'] = search.key;
            search.chipOptions['value'] = this.lastSearchFilters.metaData[
              search.key
            ]?.[search.uiKey];
          }
        }
        if (
          !search.chip &&
          (this.lastSearchFilters.filters[search.key] ||
            this.lastSearchFilters.filters[search.key] === 0)
        ) {
          this.form.controls[search.key].patchValue(
            this.lastSearchFilters.filters[search.key]
          );
        }
        if (search.dateRange && search.chipOptions.value) {
          search.chipOptions['target'] = search.dateRangeKey;
          search.chipOptions.value = this.lastSearchFilters.filters[search.key]
            ? this.lastSearchFilters.filters[search.key] +
              ' ~ ' +
              this.lastSearchFilters.filters[search.dateRangeKey]
            : '';
          this.lastSearchFilters.metaData[search.dateRangeKey][
            search.dateRangeKey
          ] = '';
          this.dataService.searchFilters.metaData[search.key][search.uiKey] =
            '';
        }
        if (search.type === 'multichip') {
          this.dataService.initDataForMultiChip =
            this.lastSearchFilters.metaData[search.key][search.uiKey] || [];
          this.dataService.dataToSendForSearchMultiChip[search.key] =
            this.lastSearchFilters.filters[search.key] || [];
        }
      });
    }
  }

  SearchOnEnter() {
    this.applyFormData();
  }

  applyFormData() {
    this.dataService.refreshList.subscribe(data => {
      if (data) {
        this.lastSearchFilters = {
          count: 0,
          metaData: {},
          filters: {},
          isFilterPreFilled: false
        };
      }
    });
    if (
      !this.dataService.isEquivalent(
        this.lastSearchFilters,
        this.dataService.searchFilters
      )
    ) {
      this.dataService.listPageIndex = 0;
      this.lastSearchFilters = this.dataService.deepClone(
        this.dataService.searchFilters
      );
      if (this.dataService.searchFilters.count >= 0) {
        this.step = false;
        this.lastSearchFilters.filters = this.dataService.removeEmptyValue(
          this.lastSearchFilters.filters
        );
        this.dataService.paginationData.pageNo = 1;
        this.searchFilters.emit(this.lastSearchFilters.filters);
      } else {
        this.resetFormData();
      }
      this.dataService.setLocalStorage(
        environment.localStorageKeys.FILTERS,
        this.dataService.searchFilters
      );
    }
  }
  valueSend(search: object) {
    search['module'] = 'search';
    return search;
  }

  resetFormData() {
    const storageFilters = this.dataService.getLocalStorage(
      environment.localStorageKeys.FILTERS
    );
    this.dataService.removeFromLocalStorage(
      environment.localStorageKeys.FILTERS
    );
    this.dataService.removeFromLocalStorage(
      environment.localStorageKeys.PAGESIZE
    );
    this.dataService.removeFromLocalStorage(
      environment.localStorageKeys.OPTIONPARAMS
    );
    this.dataService.listPageIndex = undefined;
    this.form.reset();
    this.dataService.resetSearchData();
    this.dataService.dataToSendForSearchMultiChip['clusterId'] = [];
    this.dataService.initDataForMultiChip = [];
    if (storageFilters && storageFilters.isFilterPreFilled) {
      this.dataService.searchFilters.isFilterPreFilled = true;
      this.dataService.setLocalStorage(
        environment.localStorageKeys.FILTERS,
        this.dataService.searchFilters
      );
    }

    if (this.lastSearchFilters.count !== 0) {
      this.step = false;
    }
    this.setDefaultFilter();
  }
  setDefaultFilter() {
    this._search.forEach(search => {
      if (search.chip) {
        search.chipOptions = {};
        if (search.type !== 'multichip') {
          search.options = [];
        }
      }
      if (search.checkbox) {
        search.checkbox = false;
      }
      if (search.defaultValue || search.defaultValue === '') {
        if (search.type === formServiceActions.date) {
          if (search.defaultValue instanceof Date) {
            if (search.defaultValue instanceof Date) {
              this.dataService.setSearchData(
                search,
                this.dataService.dateFormat(search.defaultValue),
                search.key,
                this.dataService.dateFormat(search.defaultValue),
                true
              );
              search.chipOptions = {
                key: search.key,
                value: this.dataService.dateFormat(search.defaultValue),
                uiKey: search.uiKey,
                apiKey: search.apiKey
              };
            } else {
              const startDate = this.dataService.dateFormat(
                search.defaultValue[0]
              );
              const endDate = this.dataService.dateFormat(
                search.defaultValue[1]
              );
              this.dataService.setSearchData(
                search,
                startDate,
                search.key,
                startDate,
                true
              );
              this.dataService.setSearchData(
                search,
                endDate,
                search.dateRangeKey,
                endDate,
                true
              );
              search.chipOptions = {
                key: search.key,
                value: startDate + ' ~ ' + endDate,
                uiKey: search.uiKey,
                apiKey: search.apiKey,
                target: search.dateRangeKey
              };
            }
          }
        } else if (
          typeof search.defaultValue === 'number' ||
          typeof search.defaultValue === 'string'
        ) {
          this.dataService.setSearchData(
            search,
            search.defaultValue,
            search.key,
            search.defaultValue,
            true
          );
          this.form.controls[search.key].patchValue(search.defaultValue);
          if ('chipOptions' in search) {
            if (search.type === 'select') {
              let chipValue: string;
              for (let i = 0; i < search.options.length; i++) {
                if (search.options[i].value === search.defaultValue) {
                  chipValue = search.options[i].placeholder;
                  break;
                }
              }
              search.chipOptions = {
                key: search.key,
                value: chipValue,
                uiKey: search.uiKey,
                apiKey: search.apiKey
              };
            } else {
              search.chipOptions = {
                key: search.key,
                value: search.defaultValue,
                uiKey: search.uiKey,
                apiKey: search.apiKey
              };
            }
          }
        }
      }
    });
    this.applyFormData();
    this.lastSearchFilters = this.dataService.deepClone(
      this.dataService.searchFilters
    );
    this.dataService.setLocalStorage(
      environment.localStorageKeys.FILTERS,
      this.dataService.searchFilters
    );
  }

  ngOnDestroy() {
    if (!this.dataService.goToViewPage) {
      this.dataService.removeFromLocalStorage(
        environment.localStorageKeys.FILTERS
      );
      this.dataService.removeFromLocalStorage(
        environment.localStorageKeys.PAGESIZE
      );
      this.dataService.removeFromLocalStorage(
        environment.localStorageKeys.OPTIONPARAMS
      );
      this.dataService.paginationData.pageNo = 1;
      this.dataService.resetSearchData();
    } else {
      this.dataService.listPageIndex =
        this.dataService.paginationData.pageNo - 1;
      this.dataService.goToViewPage = false;
    }
    this.dataService.listPageIndex = undefined;
    this.dataService.initDataForMultiChip = [];
    this.dataService.dataToSendForSearchMultiChip = {};
  }
}
