<ng-container *ngIf="!!fileUrl">
<ng-container [ngSwitch]="fileType">
  <div class="fileContainer" *ngSwitchCase="'image'">
      <img alt="{{fileName}}" class="imageBlock" [src]="fileUrl">
      <a class="textBlock" href="{{fileUrl}}" target="_blank">{{fileName}}</a>
  </div>
  <div class="fileContainer" *ngSwitchCase="'pdf'">
    <mat-icon class="pdfBlock">note</mat-icon>
    <a class="textBlock" href="{{fileUrl}}" target="_blank">{{fileName}}</a>
  </div>
</ng-container>
</ng-container>
