import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { DialogData } from 'src/app/shared/interface/interface';
import { FormService } from 'src/app/shared/services/form/form.service';
import { FormGroup } from '@angular/forms';
import { MakerCheckerService } from 'src/app/shared/services/maker-checker/maker-checker.service';
import { DataService } from 'src/app/shared/services/data/data.service';

@Component({
  selector: 'app-approve-or-reject-dialog',
  templateUrl: './approve-or-reject-dialog.component.html',
  styleUrls: ['./approve-or-reject-dialog.component.scss'],
})
export class ApproveOrRejectDialogComponent implements OnInit, AfterViewInit {
  MAX_LEVEL = 3;
  requestType = null;
  elementData;
  title:string;
  makerCheckerCrudRequest =  [];
  makerCheckerCrud = [
    {
      key: 'assignedTo',
      placeholder: 'Assigned To',
      type: 'select',
      options: [],
      validations: { required: true },
      defaultValue: '',
      validationMessages: [''],
      uiKey: 'name',
      apiKey: 'userId',
      isReadOnly: false,
    },
    {
      key: 'remarks',
      placeholder: 'Remarks',
      type: 'textarea',
      defaultValue: '',
      isReadOnly: false,
    },
  ];
  @ViewChild('btnRef', { static: false }) buttonRef: MatButton;
  form: FormGroup;
  remarkRequired: boolean = false;
  crud;

  constructor(
    public dialogRef: MatDialogRef<ApproveOrRejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formService: FormService,
    private makerCheckerService: MakerCheckerService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.requestType = this.data['requestType'];
    this.elementData = this.data['elementData'];
    this.title = this.data['title'];
    if(this.elementData.level > this.MAX_LEVEL || this.elementData.action === 'reject') {
      if(this.elementData.action === 'reject') {
        this.remarkRequired = true;
        const remarkBlock = {...{},...this.makerCheckerCrud[1],...{validations: { required: true }},...{validationMessages: ['Remark is mandatory']}}
        console.log(remarkBlock, "remarkBlock",this.makerCheckerCrud[1]);
        this.makerCheckerCrudRequest = [remarkBlock];
      } else {

      }
      this.makerCheckerCrudRequest = [this.makerCheckerCrud[1]];
      console.log(this.makerCheckerCrudRequest);
      
      this.form = this.formService.createFormGroup(this.makerCheckerCrudRequest);
    } else {
      this.fetchAssignees();
    }
  }

  fetchAssignees() {
    this.makerCheckerService
      .getAllDataById(
        `${AppWebRoutes.MAKER_CHECKER.config}?requestType=${
          this.requestType
        }&level=${this.elementData.level || 1}`,
        {}
      )
      .subscribe((response: any) => {
        this.makerCheckerCrud[0].options =
          response?.approver.map((approver) => ({
            value: approver.userId,
            placeholder: `${approver.name} (${approver.email})`,
          })) || [];
        if(this.makerCheckerCrud[0]?.options?.length >0) {
          this.makerCheckerCrudRequest = this.makerCheckerCrud;
          this.form = this.formService.createFormGroup(this.makerCheckerCrudRequest);
        } else {
          this.makerCheckerCrudRequest = [this.makerCheckerCrud[1]];
          this.form = this.formService.createFormGroup(this.makerCheckerCrudRequest);
        }
      });
  }

  fetchRequestBody() {
    let requestBody = {
      action: this.elementData.action,
      assignedTo: this.form?.controls['assignedTo']?.value || null,
      remarks: this.form?.controls['remarks']?.value,
    };
    if(!requestBody?.assignedTo && this.dataService.userProfile && this.dataService.userProfile.id) {
      requestBody.assignedTo = this.dataService.userProfile.id;
    }
    return requestBody;
  }

  onProceed() {
    const requestBody = {
      ...this.fetchRequestBody(),
    };
    if(this.elementData.action === 'reject' && !requestBody.remarks) {
      return;
    }
    this.makerCheckerService
      .edit(AppWebRoutes.MAKER_CHECKER.makerChecker+`/${this.elementData.id}`, { ...requestBody })
      .subscribe(() => {
          this.dataService.refreshList.next(true);
          this.dialogRef.close();
      });
  }

  ngAfterViewInit(): void {
    if (this.buttonRef) {
      this.buttonRef.focus();
    }
  }
}
