import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../../interface/interface';
import { AppWebRoutes } from 'src/app/core/constants/app-web-routes';
import { ApproveLogStatus, MakerCheckerType } from 'src/app/shared/constants/shared-constant';
import { MakerCheckerService } from 'src/app/shared/services/maker-checker/maker-checker.service';

@Component({
  selector: 'app-approval-history-dialog',
  templateUrl: './approval-history-dialog.component.html',
  styleUrls: ['./approval-history-dialog.component.scss'],
})
export class ApprovalHistoryDialogComponent implements OnInit {
  dataValue;
  requestType;
  elementData;
  pageType;
  approveLogStatus = ApproveLogStatus;

  constructor(
    public dialogRef: MatDialogRef<ApprovalHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public makerCheckerService: MakerCheckerService
  ) {}

  ngOnInit() {
    this.dataValue = this.data['dataValue'];
    this.requestType = this.data['requestType'];
    this.pageType = this.data['pageType'];
    this.elementData = this.data['elementData'];
    if(this.pageType === 'Maker Checker List') {
      this.fetchCommentHistoryImpl();
    } else {
      this.fetchCommentHistory();
    }
  }

  fetchCommentHistory() {
    let idImpl = this.elementData?.id;
    if(this.requestType === MakerCheckerType.AUTO_ADD_SAMPLE){
      idImpl = this.elementData.offerId;
    } else if(this.requestType === MakerCheckerType.ORDER_SAMPLE){
      idImpl = this.elementData.orderSampleId;
    }
    this.makerCheckerService
      .getAllData(
        `${AppWebRoutes.MAKER_CHECKER.approvalLog}?requestType=${this.requestType}&requestId=${idImpl}`
      )
      .subscribe((response: any) => {
        this.dataValue = response;
      });
  }

  fetchCommentHistoryImpl() {
    this.makerCheckerService
      .getAllDataById(
        `${AppWebRoutes.MAKER_CHECKER.approvalLog}/${this.elementData?.id}`, null
      )
      .subscribe((response: any) => {
        this.dataValue = response;
      });
  }
}
