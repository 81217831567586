<div class="catalog-dialog">
  <div *ngIf="elementData">
    <h2 mat-dialog-title>{{ 'Request Detail For '+elementData.name || 'Approval Request Detail' }}</h2>
    <mat-dialog-content>
      <div>
        <button type="button"
          *ngIf="showExport"
          (click)="exportCsv()" mat-stroked-button color="primary">
          <mat-icon class="material-icons-outlined">save_alt</mat-icon>
          Export
        </button>
      </div>
      <!-- <app-label-view [_labelView]="requestView"></app-label-view> -->
      <div *ngIf="elementData">
        <app-approval-request-view [elementData]="elementData" (viewExport)="showExportButton($event)" (closeDialog)="doCloseDialog($event)"></app-approval-request-view>
      </div>
      <h4 *ngIf="dataValue && dataValue.length > 0">Previous Comment(s)</h4>
      <div class="remarksOuterContainer" *ngFor="let _label of dataValue">
        <div class="row tr-row">
          <div class="col remarksContainer">
            <span class="email"> {{ _label.email }}</span>
            <span
              class="chip remarks-status"
              [ngClass]="{
                approve: _label.action === approveLogStatus.APPROVE,
                reject: _label.action === approveLogStatus.REJECT,
                pending: _label.action === approveLogStatus.CREATE
              }"
              >{{
                _label.action === approveLogStatus.APPROVE
                  ? 'approved'
                  : _label.action === approveLogStatus.REJECT
                  ? 'rejected'
                  : 'created'
              }}</span
            >
          </div>
          <div class="date-time">
            {{
              (_label.createdOn | date : 'd MMM y') +
                ' at ' +
                (_label.createdOn | date : 'h:mm a')
            }}
          </div>
        </div>

        <div class="row tr-row remarks">
          <span class="col t-cell">
            {{ _label.remarks }}
          </span>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button color="warn" [mat-dialog-close]="false">
        Cancel
      </button>
      <button mat-button color="secondary" [disabled]="form?.invalid" [mat-dialog-close]="false"
      (click)="doAction('approve')">
        Approve
      </button>
      <button mat-button color="danger" [disabled]="form?.invalid" (click)="doAction('reject')" [mat-dialog-close]="false">Reject</button>
    </mat-dialog-actions>
  </div>
</div>
