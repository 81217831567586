import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppWebRoutes } from '../../../../core/constants/app-web-routes';
import { MakerCheckerConfig, MakerCheckerType, RequestView, SKU_TYPE } from '../../../../shared/constants/shared-constant';
import { MakerCheckerService } from '../../../../shared/services/maker-checker/maker-checker.service';
import { FormService } from '../../../../shared/services/form/form.service';
import { DataService } from 'src/app/shared/services/data/data.service';
import { environment } from 'src/environments/environment';
import { appConstants, CampaignTypes, PromocodeTypes } from 'src/app/core/constants/app-constant';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-approval-request-view',
  templateUrl: './approval-request-view.component.html',
  styleUrls: ['./approval-request-view.component.scss'],
})
export class ApprovalRequestViewComponent implements OnInit {
  @Input() elementData: any;
  @Output() viewExport = new EventEmitter();
  @Output() closeDialog = new EventEmitter();

  public requestView: Array<RequestView>;
  promoCodeDetails;

  constructor(
    public makerCheckerService: MakerCheckerService,
    public dataService: DataService,
    private formService: FormService,
  ) {}

  ngOnInit() {
    this.requestView = [];
    this.requestView[ 'page' ] =  { label: '', path: '' };
    this.fetchRequestDetails(this.elementData);
  }

  fetchRequestDetails(requestData) {
    if(!!requestData && requestData.requestType && requestData.requestId) {
      switch (requestData.requestType) {
        case MakerCheckerType.COUPON:
         this.makerCheckerService.getDataById(AppWebRoutes.COUPON.coupon, requestData.requestId + '?resolve=location,benefit,name').subscribe(async (couponDetails: any) => {
            if (couponDetails?.hubIds?.length) {
              if(couponDetails?.hubIds?.includes(-1)) {
                couponDetails['hubIds'] = "All";
              } else {
                couponDetails['hubIds'] = await this.getHubSegmentCatSubcatNames(
                  couponDetails['hubIds'],
                  AppWebRoutes.LOCATION.getAllHub
                );
              }
            }
            if (couponDetails?.segmentIds?.length) {
              if(couponDetails?.segmentIds?.includes(-1)) {
                couponDetails['segmentIds'] = "All";
              } else {
                couponDetails['segmentIds'] = await this.getHubSegmentCatSubcatNames(
                  couponDetails['segmentIds'],
                  AppWebRoutes.SEGMENTS.segmentById,
                  true
                );
              }
            }
            let benefitOnName = '';
            if(!!couponDetails && couponDetails?.benefitOns && couponDetails?.benefitOns?.length) {
              const names = couponDetails?.benefitOns.map((benifit)=>{
                return benifit.name;
              });
              benefitOnName = names.join(',');
            }
            if(!!couponDetails){
              couponDetails.isUiVisibility = couponDetails?.isUiVisibility === 1 ? 'Yes' : 'No'; 
              couponDetails.isAutoApply = couponDetails?.isAutoApply === 1 ? 'Yes' : 'No'; 
            }
            this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.COUPON].viewConfig , {...couponDetails, ...{benefitOnName}});
         });
          break;
        case MakerCheckerType.AUTO_ADD_SAMPLE:
          this.makerCheckerService.getDataById(AppWebRoutes.AUTO_ADD_SAMPLE.offer, requestData.requestId).subscribe(async (success:any) => {
            if(success) {
              success['homeVisibility'] = !!success['homeVisibility'] ? 'Yes' : 'No';
              if (success?.hubIds?.length) {
                if(success?.hubIds?.includes(-1)) {
                  success['hubName'] = "All";
                } else {
                  success['hubName'] = await this.getHubSegmentCatSubcatNames(
                    success['hubIds'],
                    AppWebRoutes.LOCATION.getAllHub
                  );
                }
              }
              if(success.offerImage) {
                success.offerImage = `${environment.file_url}banners/${success.offerImage}`;
              }
              this.makerCheckerService.getDataById(AppWebRoutes.AUTO_ADD_SAMPLE.offerSegment, requestData.requestId).subscribe(async (offerSegments:any) => { 
                if(offerSegments && offerSegments.segmentIds) {
                    if(offerSegments?.segmentIds?.includes(-1)) {
                      success['segmentIds'] = "All";
                    } else {
                      success['segmentIds'] = await this.getHubSegmentCatSubcatNames(
                        offerSegments['segmentIds'],
                        AppWebRoutes.SEGMENTS.segmentById,
                        true
                      );
                    }
                } else {
                  success['segmentIds'] = "N/A";
                }
                if(!requestData.benefitId) {
                  requestData.benefitId = await this.getBenefitDetailsBySampleId(requestData.requestId);
                }
                if(!!requestData.benefitId) {
                  const observableAll = [];
                  const benefitUrl = `catalog/offer/${requestData.requestId}/benefits/${requestData.benefitId}`;
                  observableAll.push(
                    this.makerCheckerService.getDataById(AppWebRoutes.AUTO_ADD_SAMPLE.sampleBenefit, requestData.benefitId)
                  );
                  observableAll.push(
                    this.makerCheckerService.getAllDataById(benefitUrl, {})
                  );
                  forkJoin(observableAll).subscribe(async (combinedResponse: any) => {
                    if(combinedResponse[0]) {
                      success['benefitType'] = this.setBenefitText(combinedResponse[0].benefitType);
                      success['viewType'] = this.setViewTypeText(combinedResponse[0].viewType);
                      success['maxAvailCount'] = combinedResponse[0].maxAvailCount;
                      success['minLineItem'] = combinedResponse[0].minLineItem;
                      success['basketValue'] = combinedResponse[0].basketValue;
                      success['noOfSamples'] = combinedResponse[0].noOfSamples;
                      if(combinedResponse[0].productName) {
                        success['productName'] = combinedResponse[0].productName;
                      }
                      if (combinedResponse[0]?.discountedProductsListId) {
                        const url:string = `${AppWebRoutes.COLLECTION.productListPost}/${combinedResponse[0]?.discountedProductsListId}`;
                        success['listName'] = await this.getProductListName(url);
                      }
                      if (success['hubIds'] && success['hubIds'].length > 0 && combinedResponse[0].offerAvailabilityCount ) {
                        // dsadasdasdsa
                        success['productStockQuantity'] =await this.getHubStockQuantity(
                          success['hubIds'],
                          AppWebRoutes.LOCATION.getAllHub,
                          combinedResponse[0].offerAvailabilityCount
                        );
                      }
                      if(combinedResponse[0].offerOn) {
                        switch (combinedResponse[0].offerOn) {
                          case 1:
                            success['offerOn'] = 'Line Item';
                            break;
                          case 2:
                            success['offerOn'] = 'Basket Value';
                            break;
                        }
                      }
                    }
                    if(combinedResponse[1]) {
                      success['typeName'] = combinedResponse[1].typeName;
                    }
                    this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.AUTO_ADD_SAMPLE].viewConfig , success);
                  });
                } else {
                  this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.AUTO_ADD_SAMPLE].viewConfig , success);
                }
              });
            } 
          });
          break;
        case MakerCheckerType.INVITE_CODE_VALIDITY:
          this.makerCheckerService
          .getDataById(AppWebRoutes.INVITECODE.validate, requestData.requestId)
          .subscribe((res: any) => {
            if(res && res.inviteCodeId) {
              this.makerCheckerService
              .getDataById(AppWebRoutes.INVITECODE.invitecodes, res.inviteCodeId)
              .subscribe((success: Response) => {
                success[ 'types' ] = [];

                success[ 'isConsumerUi' ] = this.dataService.setYesOrNo(success[ 'isConsumerUi' ]);
                this.promoCodeDetails = {...success, ...res};
                for (let inviteCode of success[ 'inviteCodeTypes' ]) {
                  if (!(success[ 'types' ].indexOf(inviteCode.type) > -1)) {
                    success[ 'types' ].push(inviteCode.type);
                  }
                  this.setValuesForIds(inviteCode);
                }
                this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.INVITE_CODE_VALIDITY].viewConfig, this.promoCodeDetails);
              });
            }
          });
          
          break;
        case MakerCheckerType.INVITE_CODE:
          this.makerCheckerService
          .getDataById(AppWebRoutes.INVITECODE.invitecodes, requestData.requestId)
          .subscribe((success: Response) => {
            success[ 'types' ] = [];

            success[ 'isConsumerUi' ] = this.dataService.setYesOrNo(success[ 'isConsumerUi' ]);
            this.promoCodeDetails = { ...success,
              type: success[ 'type' ] ? 'Bulk' : 'Normal'
            };
            for (let inviteCode of success[ 'inviteCodeTypes' ]) {
              if (!(success[ 'types' ].indexOf(inviteCode.type) > -1)) {
                success[ 'types' ].push(inviteCode.type);
              }
              this.setValuesForIds(inviteCode);
            }
            this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.INVITE_CODE].viewConfig, this.promoCodeDetails);
          });
          break;
        case MakerCheckerType.CAMPAIGN:
          this.makerCheckerService
          .getDataById(AppWebRoutes.MARKETING.campaign, requestData.requestId)
          .subscribe(async (success: any) => {
            if (success) {
              success[ 'active' ] = success[ 'active' ] ? 'Yes' : 'No';
              appConstants.masterData.CAMPAIGN_TYPE.forEach(data => {
                if (success[ 'type' ] === data[ 'value' ]) {
                  success[ 'type' ] = data[ 'placeholder' ];
                  success[ 'typeKey' ] = data[ 'value' ];
                }
              });
              success['skuType'] = SKU_TYPE[`${success['skuType']}`];
              let campainFor:any = await this.getCampainFor(success.id);
              if(campainFor) {
                success = {...success, ...campainFor}
              }
              let campainValidity:any = await this.getCampainValidity(success.id);
              if(campainValidity) {
                success = {...success, ...campainValidity}
              }
              this.getSegmentData(success);
            }
          });
          break;
        case MakerCheckerType.REWARD:
          this.makerCheckerService
          .getAllDataById(
            `${AppWebRoutes.REWARDS.rewards}/${requestData.requestId}?onlyActive=false`,
            null
          )
          .subscribe((success: Response) => {
            if (success) {
              this.showRewardDetails(success);
            }
          });
          break;
        case MakerCheckerType.FLASH_DEAL:
          this.makerCheckerService.getDataById(AppWebRoutes.MARKETING.getFlashDeal, requestData.requestId)
            .subscribe(async (success:any) => {
              if (success?.hubIds?.length) {
                if(success?.hubIds?.includes(-1)) {
                  success['hubIds'] = "All";
                } else {
                  success['hubIds'] = await this.getHubSegmentCatSubcatNames(
                    success['hubIds'],
                    AppWebRoutes.LOCATION.getAllHub
                  );
                }
              }
              if (success?.segmentIds?.length) {
                if(success?.segmentIds?.includes(-1)) {
                  success['segmentIds'] = "All";
                } else {
                  success['segmentIds'] = await this.getHubSegmentCatSubcatNames(
                    success['segmentIds'],
                    AppWebRoutes.SEGMENTS.segmentById,
                    true
                  );
                }
              } 
              if (success?.listId) {
                const url:string = `${AppWebRoutes.COLLECTION.productListPost}/${success[ 'listId' ]}`;
                success['listId'] = await this.getProductListName(url);
              }
              if(success.headerImg) {
                success.headerImg = `${environment.file_url}flash_deal/${success.headerImg}`;
              }
              if(success.backgroundImg) {
                success.backgroundImg = `${environment.file_url}flash_deal/${success.backgroundImg}`;
              }
              this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.FLASH_DEAL].viewConfig , success);
          });
          break;
        case MakerCheckerType.ORDER_SAMPLE:
          const url:string = `${AppWebRoutes.ORDER.addSample}/${requestData.requestId}?resolve=society`;
          this.makerCheckerService.getAllDataById(url, {})
            .subscribe(async (response:any) => {
              if(!!response) {
                let society:string = 'All';
                let hubName:any = '';
                if(response && response.orderSampleSocieties && response.orderSampleSocieties.length) {
                  const orderSociety = response.orderSampleSocieties.map((orderSociety)=>{
                    return `${orderSociety.society.name} (${orderSociety.totalOrder})`;
                  });
                  society = orderSociety.join(", ");
                }
                if (response?.hubId) {
                    hubName = await this.getHubSegmentCatSubcatNames(
                      [response?.hubId],
                      AppWebRoutes.LOCATION.getAllHub
                    );
                }
                this.makerCheckerService
                .getDataByIdWithResolve(
                  AppWebRoutes.CATALOG.productCatalog,
                  response.productId,
                  {
                    resolve: AppWebRoutes.CATALOG.resolve
                  }
                )
                .subscribe((success: any) => {
                  this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.ORDER_SAMPLE].viewConfig , {...success, ...{society}, ...{hubName}});
                });
              }
          });
          
          break;
        default:
          // need action
          // alert('Not a genuinue Request Type');
          // this.dialogRef.close();
          this.closeDialog.emit(true);
          break;
      }
    }
  }

  getSegmentData (campaign: any) {
    this.makerCheckerService
    .addData(AppWebRoutes.SEGMENTS.segmentById, campaign['segmentIds'])
    .subscribe((segmentListResponse: any) => {
      if (segmentListResponse) {
        let segment = '';
        segmentListResponse.forEach((element) => {
          if (campaign[ 'segmentIds' ].includes(element[ 'id' ])) {
            segment = segment + element[ 'name' ] + ', ';
          }
        });
        campaign[ 'segmentId' ] = segment;

        switch (campaign[ 'typeKey' ]) {
          case CampaignTypes.AUTO_ADD_SAMPLE:
            delete campaign[ 'cashback' ];
            delete campaign[ 'cashbackExpireInDays' ];
            delete campaign[ 'creditNotify' ];
            delete campaign[ 'debitNotify' ];
            delete campaign[ 'refundType' ];
            this.getProductData(campaign);
            break;

          case CampaignTypes.CASH_IN_WALLET_UNPAID:
            delete campaign[ 'productId' ];
            delete campaign[ 'quantity' ];
            campaign[ 'creditNotify' ] = campaign[ 'creditNotify' ] ? 'YES' : 'NO';
            campaign[ 'debitNotify' ] = campaign[ 'debitNotify' ] ? 'YES' : 'NO';
            this.getBucketNameCampain(campaign);
            break;

          case CampaignTypes.PAID:
            delete campaign[ 'productId' ];
            delete campaign[ 'quantity' ];
            campaign[ 'creditNotify' ] = campaign[ 'creditNotify' ] ? 'YES' : 'NO';
            campaign[ 'debitNotify' ] = campaign[ 'debitNotify' ] ? 'YES' : 'NO';
            delete campaign[ 'refundType' ];
            this.getBucketNameCampain(campaign);
            break;

          case CampaignTypes.MAHA_CASHBACK:
            delete campaign[ 'cashback' ];
            delete campaign[ 'productId' ];
            delete campaign[ 'quantity' ];
            delete campaign[ 'segmentId' ];
            delete campaign[ 'creditNotify' ];
            delete campaign[ 'debitNotify' ];
            delete campaign[ 'refundType' ];
            this.getBucketNameCampain(campaign);
            break;

          case CampaignTypes.MEMBERSHIP_DISCOUNT:
            delete campaign[ 'cashback' ];
            delete campaign[ 'productId' ];
            delete campaign[ 'quantity' ];
            delete campaign[ 'bucketId' ];
            delete campaign[ 'description' ];
            delete campaign[ 'cashbackExpireInDays' ];
            this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.CAMPAIGN].viewConfig, campaign);
            break;

          case CampaignTypes.DIFFERENTIAL_PRICING:
            // need action
            // this.showExport = true;
            this.viewExport.emit(true);
            delete campaign[ 'cashback' ];
            delete campaign[ 'productId' ];
            delete campaign[ 'quantity' ];
            delete campaign[ 'creditNotify' ];
            delete campaign[ 'debitNotify' ];
            delete campaign[ 'refundType' ];
            delete campaign[ 'cashbackExpireInDays' ];
            delete campaign[ 'bucketId' ];
            delete campaign[ 'description' ];
            this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.CAMPAIGN].viewConfig, campaign);
        }
      }
    });
  }

  getCampainFor(campainID) {
    return new Promise((resolve)=>{
      this.dataService.searchFilters.filters.campaignId = campainID;
      this.makerCheckerService.getData(AppWebRoutes.MARKETING.datalistFor, {campaignId: campainID}).subscribe((res: any) => {
        delete this.dataService.searchFilters.filters.campaignId;
        let dataForCity:string = '';
        let dataForHub:string = '';
        if (res) {
          if (res.data.length > 0) {
            res.data.filter((data) => {
              if (data.city === null && data.hub === null) {
                data.city = 'All';
                data.hub = 'All';
                dataForCity = 'All';
                dataForHub = 'All';
              } else {
                dataForCity = dataForCity + data.city;
                dataForHub = dataForHub + data.hub;
              }
            })
          }
        }
        resolve({
          city: dataForCity,
          hub: dataForHub
        })
      });
    })
  }

  getCampainValidity(campainID) {
    return new Promise((resolve)=>{
      this.makerCheckerService
      .getAllDataById(AppWebRoutes.MARKETING.validate, { campaignId: campainID })
      .subscribe((res: any) => {
        let validityStartDates= '';
        let validityExpireDate= '';
        if (res && res.length >0 ) {
          res.forEach((validity)=>{
            resolve({
              validityStartDate: validityStartDates + `${validity.startDate},`,
              validityExpireDate: validityExpireDate + `${validity.expiryDate},`
            })
          })
          
        } else {
          resolve({
            validityStartDate: '',
            validityExpireDate: ''
          })
        }
      });
    })
  }

  getBucketNameCampain (campaign: Response) {
    this.makerCheckerService.getAllDataById(`${AppWebRoutes.CASHBACK_BUCKET.cashbackReason}/${campaign[ 'bucketId' ]}`, null).subscribe((bucketInfo: any) => {
      if (bucketInfo) {
        campaign[ 'bucketId' ] = bucketInfo.bucketName;
        this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.CAMPAIGN].viewConfig, campaign);
      }
    });
  }

  getProductData (campaign: Response) {
    this.makerCheckerService
      .getDataById(AppWebRoutes.CATALOG.productCatalog, campaign[ 'productId' ])
      .subscribe((success: Response) => {
        if (success) {
          campaign[ 'productId' ] = success[ 'name' ] + '(' + success[ 'weight' ] + ')' + ' - ' + campaign[ 'productId' ];
          this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.CAMPAIGN].viewConfig, campaign);
        }
      });
  }

  setValuesForIds (inviteCode) {
    switch (inviteCode.type) {
      case PromocodeTypes.SUBCATEGORY_SCHEME:
      case PromocodeTypes.CASH_IN_WALLET_SCHEME:
      case PromocodeTypes.PRODUCT_SCHEME:
        this.makerCheckerService
          .getDataById(AppWebRoutes.SCHEMES.schemes, inviteCode.value + '?active=1')
          .subscribe((response: Response) => {
            if (response) {
              let data;
              if (inviteCode.type === 'SUBCATEGORY_SCHEME') {
                data = { 'SCHEME_SUBCATEGORY': response[ 'name' ] };
              } else if (inviteCode.type === 'PRODUCT_SCHEME') {
                data = { 'PRODUCT_SCHEME': response[ 'name' ] };
              }
              else {
                data = { 'SCHEME_CASH_IN_WALLET': response[ 'name' ] };
              }
              this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.INVITE_CODE].viewConfig, data);
            }
          });
        break;
      case PromocodeTypes.TOPUP_CASHBACK:
        if (inviteCode.key === 'RANGE') {
          this.promoCodeDetails = { ...this.promoCodeDetails, ...{ 'RANGE': inviteCode.value } };
        } else if (inviteCode.key === 'APPLICABLE') {
          if (inviteCode.value === '1') {
            this.promoCodeDetails = { ...this.promoCodeDetails, ...{ 'APPLICABLE': 'Fixed' } };
          } else {
            this.promoCodeDetails = { ...this.promoCodeDetails, ...{ 'APPLICABLE': 'Percentage' } };
          }
        } else {
          this.promoCodeDetails = { ...this.promoCodeDetails, ...{ 'TOPUP_CASHBACK': inviteCode.value } };
        }
        break;
      case PromocodeTypes.WELCOME_CASHBACK:
        this.promoCodeDetails = { ...this.promoCodeDetails, ...{ 'AMOUNT': inviteCode.value } };
        break;
      case PromocodeTypes.SAMPLE_LIST_PRODUCT:
        this.makerCheckerService.getDataById(AppWebRoutes.COLLECTION.productListPost, inviteCode[ 'value' ]).subscribe((res: any) => {
          if (res) {
            this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.INVITE_CODE].viewConfig, { 'LIST': res.name });
          }
        });
        break;
      case PromocodeTypes.MB_PASS:
        this.makerCheckerService.getDataById(`${AppWebRoutes.MB_PASS.mbPass}`, inviteCode[ 'value' ]).subscribe((res: any) => {
          if (res) {
            this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.INVITE_CODE].viewConfig, { 'PASS_ID': res.name });
          }
        });
        break;
    }
  }

  async showRewardDetails(successResponse) {
    successResponse['rewardType'] === 1 &&
      (successResponse['rewardType'] = 'Cashback');
    successResponse['level'] === 1 && (successResponse['level'] = 'Category');
    if (successResponse['level'] === 1) {
      successResponse['level'] = 'Category';
    } else if (successResponse['level'] === 2) {
      successResponse['level'] = 'Subcategory';
    }
    if (successResponse['tnc']) {
      successResponse['tnc'] = successResponse['tnc'].split('\n');
      successResponse['tnc'] = successResponse['tnc'].map((tnc) => {
        return `- ${tnc}`;
      });
    }
    if (successResponse['targetType'] === 1) {
      successResponse['targetType'] = 'Monthly';
    } else if (successResponse['targetType'] === 2) {
      successResponse['targetType'] = 'Weekly';
    }
    if (successResponse?.hubIds?.length) {
      successResponse['hubIds'] = await this.getHubSegmentCatSubcatNames(
        successResponse['hubIds'],
        AppWebRoutes.LOCATION.getAllHub
      );
    }
    if (successResponse?.segmentIds?.length) {
      successResponse['segmentIds'] = await this.getHubSegmentCatSubcatNames(
        successResponse['segmentIds'],
        AppWebRoutes.SEGMENTS.segmentById,
        true
      );
    }
    if (successResponse?.subcategoryIds?.length) {
      successResponse['subcategoryIds'] =
        await this.getHubSegmentCatSubcatNames(
          successResponse['subcategoryIds'],
          AppWebRoutes.SUBCATEGORIES.findByIds,
          false
        );
    }
    if (successResponse?.categoryId) {
      successResponse['categoryId'] = await this.getHubSegmentCatSubcatNames(
        [successResponse['categoryId']],
        AppWebRoutes.CATEGORIES.categoriesAll,
        false
      );
    }
    if (successResponse?.cashbackBucketId) {
      successResponse['cashbackBucketId'] = await this.getBucketName(
        successResponse['cashbackBucketId']
      );
    }
    this.formService.patchValue(this.requestView, MakerCheckerConfig[MakerCheckerType.REWARD].viewConfig , successResponse);
  }

  getBucketName(cashbackBucketId) {
    return new Promise((resolve, reject) => {
      this.makerCheckerService
        .getAllDataById(
          `${AppWebRoutes.CASHBACK_BUCKET.cashbackReason}/${cashbackBucketId}`,
          null
        )
        .subscribe(
          (bucketInfo: any) => {
            if (bucketInfo) {
              resolve(bucketInfo.bucketName);
            }
          },
          (err) => reject(null)
        );
    });
  }

  getHubSegmentCatSubcatNames(arrayOfItems, apiEndPoint, showId = false) {
    let hubSegmentCatSubcatNamesArray = '';
    return new Promise((resolve, reject) => {
      this.makerCheckerService.addData(apiEndPoint, arrayOfItems).subscribe(
        (res: any) => {
          if (res) {
            res.forEach((recurringHubIds) => {
              hubSegmentCatSubcatNamesArray +=
                `${recurringHubIds['name']} ` +
                (showId ? `(${recurringHubIds['id']}),` : ',');
            });
            resolve(hubSegmentCatSubcatNamesArray);
          }
        },
        (err) => reject(null)
      );
    });
  }

  getHubStockQuantity(arrayOfItems, apiEndPoint, stockQuantity) {
    let hubSegmentCatSubcatNamesArray = '';
    return new Promise((resolve, reject) => {
      this.makerCheckerService.addData(apiEndPoint, arrayOfItems).subscribe(
        (res: any) => {
          if (res) {
            res.forEach((recurringHubIds) => {
              hubSegmentCatSubcatNamesArray +=
                `${recurringHubIds['name']}` +
                (`(${stockQuantity[recurringHubIds['id']]}),`);
            });
            resolve(hubSegmentCatSubcatNamesArray);
          }
        },
        (err) => reject(null)
      );
    });
  }

  getProductListName(apiEndPoint) {
    let listName = '';
    return new Promise((resolve, reject) => {
      this.makerCheckerService.getAllData(apiEndPoint).subscribe(
        (res: any) => {
          if (res) {
            listName = res['name'];
            resolve(listName);
          }
        },
        (err) => reject(null)
      );
    });
  }

  setBenefitText (benefitValue) {
    let benefitText: string = 'N/A';
    switch (benefitValue) {
      case 1:
        benefitText = "Sample Product";
        break;
      case 2:
        benefitText = "MB Pass";
        break;
      case 3:
        benefitText = "Cashback";
        break;
      case 4:
        benefitText = "Discounted Products";
        break;
    }
    return benefitText;
  }

  setViewTypeText (viewTypeValue) {
    let viewTypeText: string = 'N/A';
    switch (viewTypeValue) {
      case 1:
        viewTypeText = "Detailed View";
        break;
      case 2:
        viewTypeText = "Undisclosed View";
        break;
    }
    return viewTypeText;
  }

  getBenefitDetailsBySampleId (sampleId:number) {
    return new Promise((resolve)=>{
      this.makerCheckerService.getDataById(AppWebRoutes.AUTO_ADD_SAMPLE.sampleBenefitDetailsBySampleId, sampleId).subscribe((res: any)=>{
        resolve(res?.id || 0)
      }, (err)=>{
        console.log("error ", err);
        resolve(0);
      })
    })
  }

}
