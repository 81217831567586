import { AppWebRoutes } from "src/app/core/constants/app-web-routes";

export const inputComponentActions = {
  lowercase_with_delimiter_underscore: 'lowercase_with_delimiter_underscore',
  coordinates: 'coordinates',
  lower_case: 'lower_case',
  phone_validation: 'phone_validation',
  email_validation: 'email_validation',
  number_validation: 'number_validation',
  search: 'search',
  addition_of_fields: 'addition_of_fields',
  multiplication_of_fields: 'multiplication_of_fields'
};
// Have to move here because it was initiating masterData variables of customerConstant before it was getting from storage or by API. 
export const mbPassLedgerContainer = {
  tableHeader: [
    { header: 'Date', key: 'date' },
    { header: 'Order Id', key: 'orderId' },
    { header: 'Pass Saving', key: 'passSaving' },
    { header: 'Refund', key: 'refund' }
  ],
  metaData: {
    header: 'Mb Pass',
    canEdit: false,
    canDelete: false,
    hidePagination: true,
    showRefresh: false,
    showCreate: false,
    viewPrivilege: 'view_customer'
  },
};
export const autoCompleteComponentActions = {
  auto_update_dependent_list: 'auto_update_dependent_list',
  auto_update_dependent_list_depend: 'auto_update_dependent_list_depend'
};
export const selectComponentActions = { hide_corresponding_field: 'hide_corresponding_field', search: 'search', disable_next_field: 'disable_next_field' };
export const dateComponentActions = { calendar: 'calendar', both: 'both', time: 'time', search: 'search' };
export const radioComponentActions = { hide_corresponding_field: 'hide_corresponding_field' };
export const shared = {
  NA: 'N/A'
};
export const resolveParams = {
  hub: 'hub',
  locations: 'locations',
  vendor: 'vendor'
};

export const formServiceActions = {
  search: 'search',
  date: 'date',
};
export const moduleType = {
  search: 'search',
  crud: 'crud'
};

export const fileSizeGlobal = {
  gif: 5242880, // this byte is equal to 5MB
  defaultSize: 5242880 // this byte is equal to 5MB,
};

export enum CSVConstants {
  CSV_ERROR = 'There are issues with CSV file',
  PLACEHOLDER = 'File Upload'
};

export enum OPEN_SNACKBAR_CLASSES {
  DANGER = 'danger',
  SUCCESS = 'success'
};

export enum ERROR_MESSAGES {
  SCHEDULE_NOT_ALLOWED = 'schedule_not_allowed',
  BIN_ERROR = 'Bin Number Should be 6 digit.',
};

export enum PaymentMethod {
  WALLET = 'WALLET',
  DEBIT_CARD = 'DEBIT_CARD',
  CREDIT_CARD = 'CREDIT_CARD',
  UPI = 'UPI',
  PAY_LATER = 'PAY_LATER',
  NET_BANKING = 'NET_BANKING',
  FOOD_WALLET = 'FOOD_WALLET',
  CRED = 'CRED'
}

export enum MakerCheckerStatus{
  DRAFTED = null,
  PENDING_FOR_APPROVAL = 0,
  APPROVED = 1,
  REJECTED = 2 ,
  EXPIRED = 3,
}

export enum ApproveLogStatus{
  CREATE = 'create',
  APPROVE = 'approve',
  REJECT = 'reject',
  EXPIRED = 'expired'
}

export enum MakerCheckerType {
  INVITE_CODE = 'invite_code',
  INVITE_CODE_VALIDITY = 'invite_code_validity',
  CAMPAIGN = 'campaign',
  AUTO_ADD_SAMPLE = 'auto_add_sample',
  COUPON = 'coupon',
  REWARD = 'reward',
  FLASH_DEAL = 'flash_deal',
  ORDER_SAMPLE = 'order_sample'
}

export const MakerCheckerConfig = {
  [MakerCheckerType.INVITE_CODE]: {
    id: 1,
    name: 'Invite Code',
    viewDataUrl: AppWebRoutes.INVITECODE.invitecodes,
    viewConfig:[
      { placeholder: 'Name', key: 'name' },
      { placeholder: 'Type', key: 'type' },
      { placeholder: 'Benefit Type', key: 'types' },
      { placeholder: 'info', key: 'info' },
      { placeholder: 'Location Type', key: 'locationType' },
      { placeholder: 'Location', key: 'locationValue' },
      { placeholder: 'Sample product list', key: 'LIST' },
      { placeholder: 'Topup cashback range', key: 'RANGE' },
      { placeholder: 'Topup max cashback', key: 'TOPUP_CASHBACK' },
      { placeholder: 'Topup applicability', key: 'APPLICABLE' },
      { placeholder: 'MB Pass', key: 'PASS_ID' },
      { placeholder: 'Scheme (cash in wallet)', key: 'SCHEME_CASH_IN_WALLET' },
      { placeholder: 'Scheme (subcategory)', key: 'SCHEME_SUBCATEGORY' },
      { placeholder: 'Scheme (product)', key: 'PRODUCT_SCHEME' },
      { placeholder: 'Welcome cashback amount', key: 'AMOUNT' },
      { placeholder: 'Consumer Visibility', key: 'isConsumerUi' },
      { placeholder: 'Short Description', key: 'shortDescription' },
      { placeholder: 'Minimum Topup Value', key: 'minTopupValue' },
      { placeholder: 'Quantity of Codes', key: 'quantityOfCodes' },
      { placeholder: 'Redemption Limit', key: 'redemptionLimit' },
    ]
  },
  [MakerCheckerType.CAMPAIGN]: {
    id: 2,
    name: 'Campaign',
    viewConfig: [
      { placeholder: 'Name', key: 'name' },
      { placeholder: 'Campain For Cities', key: 'city' },
      { placeholder: 'Campain For Hubs', key: 'hub' },
      { placeholder: 'Campain Validity Start', key: 'validityStartDate' },
      { placeholder: 'Campain Validity Expire', key: 'validityExpireDate' },
      { placeholder: 'Description', key: 'description' },
      { placeholder: 'Type', key: 'type' },
      { placeholder: 'Segment', key: 'segmentId' },
      { placeholder: 'Active', key: 'active' },
      { placeholder: 'Product', key: 'productId' },
      { placeholder: 'Quantity', key: 'quantity' },
      { placeholder: 'Cashback Expire In Days', key: 'cashbackExpireInDays' },
      { placeholder: 'Cashback', key: 'cashback' },
      { placeholder: 'Sms is to be sent for Debit', key: 'debitNotify' },
      { placeholder: 'Sms is to be sent for Credit', key: 'creditNotify' },
      { placeholder: 'Refund Type', key: 'refundType' },
    ],
    // viewDataUrl: AppWebRoutes.CAMPAIGN.campaign
  },
  [MakerCheckerType.REWARD]: {
    id: 3,
    name: 'Reward',
    viewConfig: [
      { placeholder: 'Reward Name', key: 'name' },
      { placeholder: 'Hubs', key: 'hubIds' },
      { placeholder: 'Segments', key: 'segmentIds' },
      { placeholder: 'Start Date', key: 'startDate' },
      { placeholder: 'End Date', key: 'endDate' },
      { placeholder: 'Reward Type', key: 'rewardType' },
      { placeholder: 'Level', key: 'level' },
      { placeholder: 'Category', key: 'categoryId' },
      { placeholder: 'Subcategories', key: 'subcategoryIds' },
      { placeholder: 'Terms & Conditions', key: 'tnc' },
      { placeholder: 'Bucket Name', key: 'cashbackBucketId' },
      { placeholder: 'Reward Amount', key: 'rewardAmount' },
      { placeholder: 'Target Type', key: 'targetType' },
      { placeholder: 'Target Order Count', key: 'targetOrders' },
      { placeholder: 'Target Order Amount', key: 'targetAmount' },
    ]
  },
  [MakerCheckerType.FLASH_DEAL]: {
    id: 4,
    name: 'Flash Deal',
    viewConfig: [{"key":"hubIds","placeholder":"Hub"},{"key":"name","placeholder":"Name"},{"key":"description","placeholder":"Description"},{"key":"startTime","placeholder":"Start Time"},{"key":"endTime","placeholder":"End Time"},{"key":"headerImg","placeholder":"Flash Deal Header Image"},{"key":"backgroundImg","placeholder":"Flash Deal Backgroung Image"},{"key":"segmentIds","placeholder":"Segment"},{"key":"mov","placeholder":"Minimum Order Value"},{"key":"maxQuantity","placeholder":"Max Quantity"},{"key":"listId","placeholder":"Product List"},{"key":"tnc","placeholder":"Terms And Conditions"}],
  },
  [MakerCheckerType.COUPON]: {
    id: 5,
    name: 'Coupon',
    viewConfig: [{"key":"name","placeholder":"Coupon Name"},{"key":"shortDesc","placeholder":"Heading"},{"key":"longDesc","placeholder":"Sub-heading"},{"key":"detail","placeholder":"Detail"},{"key":"startDate","placeholder":" Start Date"},{"key":"endDate","placeholder":"End Date"},{"key":"hubIds","placeholder":"Hub"},{"key":"segmentIds","placeholder":"Segments"},{"key":"type","placeholder":"Reward Type"},{"key":"rewardType","placeholder":"Offer Type"},{"key":"benefitOnType","placeholder":"Benefit On Type"},{"key":"benefitOnName","placeholder":"Benefit On"},{"key":"reward","placeholder":"% Discount"},{"key":"mov","placeholder":"Minimum Order Value"},{"key":"perUserLimit","placeholder":"Per user usage"},{"key":"maxLimit","placeholder":"Per coupon usage"},{"key":"maxReward","placeholder":"Max Reward to avail"},{"key":"isUiVisibility","placeholder":"Consumer Visibility"},{"key":"isAutoApply","placeholder":"Auto Applicable"},{"key":"tnc","placeholder":"Terms And Condition"}],
  },
  [MakerCheckerType.AUTO_ADD_SAMPLE]: {
    id: 6,
    name: 'Auto Add Sample',
    viewConfig: [
      {"key":"name","placeholder":"Offer Name"},
      {"key":"startDate","placeholder":"Start Date"},
      {"key":"nudgeText","placeholder":"Nudge Text"},
      {"key":"endDate","placeholder":"End Date"},
      {"key":"benefitType","placeholder":"Benefit Type"},
      {"key":"productName","placeholder":"Product Name"},
      {"key":"listName","placeholder":"List Name"},
      {"key":"hubName","placeholder":"Hub Ids"},
      {"key":"offerOn","placeholder":"Offer On"},
      {"key":"typeName","placeholder":"Offer Type"},
      {"key":"viewType","placeholder":"View Type"},
      {"key":"segmentIds","placeholder":"Segment"},
      {"key":"pushText","placeholder":"Push Text"},
      {"key":"pushHeading","placeholder":"Push Heading"},
      {"key":"smsText","placeholder":"SMS Text"},
      {"key":"homeVisibility","placeholder":"Home Visibility"},
      {"key":"head","placeholder":"Heading"},
      {"key":"subHead","placeholder":"Sub Heading"},
      {"key":"maxAvailCount","placeholder":"Max eligibility count"},
      {"key":"productStockQuantity","placeholder":"Product Stock Quantity Hub"},
      {"key":"minLineItem","placeholder":"Min Line Item"},
      {"key":"basketValue","placeholder":"Min Basket Value"},
      {"key":"noOfSamples","placeholder":"Number Of Samples"},
      {"key":"offerImage","placeholder":"Offer Image"}],
  },
  [MakerCheckerType.ORDER_SAMPLE]: {
    id: 7,
    name: 'Order Sample',
    viewConfig: [
      { placeholder: 'Product Id', key: 'id' },
      { placeholder: 'Rr Product Id', key: 'rrProductId' },
      { placeholder: 'Product Name', key: 'name' },
      { placeholder: 'SKU', key: 'sku', resolve: 'sku' },
      { placeholder: 'Brand', key: 'name', resolve: 'brand' },
      { placeholder: 'Model', key: 'model' },
      { placeholder: 'HSN Code', key: 'hsnCode' },
      { placeholder: 'Vendor', key: 'vendorId' },
      { placeholder: 'Category', key: 'name', resolve: 'category' },
      { placeholder: 'Sub Category', key: 'name', resolve: 'subcategory' },
      {
        placeholder: 'Sub category Type',
        key: 'name',
        resolve: 'subcategoryType'
      },
      { placeholder: 'Weight', key: 'weight' },
      { placeholder: 'Hub Name', key: 'hubName' },
      { placeholder: 'Societies (Total Order)', key: 'society' },
      { placeholder: 'Total Order', key: 'totalOrder' },
    ]
  },
  [MakerCheckerType.INVITE_CODE_VALIDITY]: {
    id: 1,
    name: 'Invite Code',
    viewDataUrl: AppWebRoutes.INVITECODE.invitecodes,
    viewConfig:[
      { placeholder: 'Validity Start Date', key: 'startDate' },
      { placeholder: 'Validity End Date', key: 'endDate' },
      { placeholder: 'Name', key: 'name' },
      { placeholder: 'Type', key: 'types' },
      { placeholder: 'info', key: 'info' },
      { placeholder: 'Location Type', key: 'locationType' },
      { placeholder: 'Location', key: 'locationValue' },
      { placeholder: 'Sample product list', key: 'LIST' },
      { placeholder: 'Topup cashback range', key: 'RANGE' },
      { placeholder: 'Topup max cashback', key: 'TOPUP_CASHBACK' },
      { placeholder: 'Topup applicability', key: 'APPLICABLE' },
      { placeholder: 'MB Pass', key: 'PASS_ID' },
      { placeholder: 'Scheme (cash in wallet)', key: 'SCHEME_CASH_IN_WALLET' },
      { placeholder: 'Scheme (subcategory)', key: 'SCHEME_SUBCATEGORY' },
      { placeholder: 'Scheme (product)', key: 'PRODUCT_SCHEME' },
      { placeholder: 'Welcome cashback amount', key: 'AMOUNT' },
      { placeholder: 'Consumer Visibility', key: 'isConsumerUi' },
      { placeholder: 'Short Description', key: 'shortDescription' },
    ]
  },
};

export interface RequestView {
  placeHolder: string;
  value: string;
  key: string;
};

export const SKU_TYPE = {
  0: 'Sample',
  1: 'Delivery Bag',
  2: 'Normal',
};